<template>

    <div class="mt-3 row">

        <div class="mt-4 col-12 col-md-9 mt-md-0">
            <profile-info-card
                title="Member Information"
                :info="{
                    name: member?.user?.name,
                    email: member?.user?.email,
                    mobile: member?.user?.phone,
                    address: member?.user?.address,
                    location: member?.user?.country,
                }"
                :social="[{
                    link: member?.user?.social?.facebook,
                    icon: faFacebook,
                },
                {
                    link: member?.user?.social?.twitter,
                    icon: faTwitter,
                },
                {
                    link: member?.user?.social?.instagram,
                    icon: faInstagram,
                }]"
                :action="{
                    route: 'javascript:;',
                    tooltip: 'Edit Profile',
                }"
            />
        </div>

        <!-- RECENT ACTIVITY -->
        <div class="mt-4 col-12 col-md-3 mt-xl-0">
            <div class="card h-100">
                <div class="p-3 pb-0 card-header">
                    <h6 class="mb-0">Recent Activity</h6>
                </div>
                <div class="p-3 card-body">
                    <ul class="list-group">

                        <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
                            <img
                                class="me-3"
                                width="24"
                                height="24"
                                src="~@/assets/img/icons/reservation.svg"
                                alt="Reservation"
                                border-radius="lg"
                            />
                            <div class="d-flex align-items-start flex-column justify-content-center">
                                <h6 class="mb-0 text-sm">Reservations</h6>
                                <!-- <p class="mb-0 text-xs">Hi! I need more information..</p> -->
                            </div>
                            <a class="mb-0 btn btn-link pe-3 ps-0 ms-auto" href="javascript:;">{{ member?.reservations_count || 0 }}</a>
                        </li>

                        <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
                            <img
                                class="me-3"
                                width="24"
                                height="24"
                                src="~@/assets/img/icons/booking.svg"
                                alt="Booking"
                                border-radius="lg"
                            />
                            <div class="d-flex align-items-start flex-column justify-content-center">
                                <h6 class="mb-0 text-sm">Bookings</h6>
                                <!-- <p class="mb-0 text-xs">Awesome work, can you..</p> -->
                            </div>
                            <a class="mb-0 btn btn-link pe-3 ps-0 ms-auto" href="javascript:;">{{ member?.bookings_count || 0 }}</a>
                        </li>

                        <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
                            <img
                                class="me-3"
                                width="24"
                                height="24"
                                src="~@/assets/img/icons/ticket.svg"
                                alt="Booking"
                                border-radius="lg"
                            />
                            <div class="d-flex align-items-start flex-column justify-content-center">
                                <h6 class="mb-0 text-sm">Tickets Order</h6>
                                <!-- <p class="mb-0 text-xs">Awesome work, can you..</p> -->
                            </div>
                            <a class="mb-0 btn btn-link pe-3 ps-0 ms-auto" href="javascript:;">{{ member?.tickets_count || 0 }}</a>
                        </li>

                        <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
                            <img
                                class="me-3"
                                width="24"
                                height="24"
                                src="~@/assets/img/icons/bottle-order.svg"
                                alt="Bottles Order"
                                border-radius="lg"
                            />
                            <div class="d-flex align-items-start flex-column justify-content-center">
                                <h6 class="mb-0 text-sm">Bottles Order</h6>
                                <!-- <p class="mb-0 text-xs">About files I can..</p> -->
                            </div>
                            <a class="mb-0 btn btn-link pe-3 ps-0 ms-auto" href="javascript:;">{{ member?.bottle_order_count }}</a>
                        </li>

                        <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
                            <img
                                class="me-3"
                                width="24"
                                height="24"
                                src="~@/assets/img/icons/career.svg"
                                alt="Careers"
                                border-radius="lg"
                            />
                            <div class="d-flex align-items-start flex-column justify-content-center">
                                <h6 class="mb-0 text-sm">Careers</h6>
                                <!-- <p class="mb-0 text-xs">About files I can..</p> -->
                            </div>
                            <a class="mb-0 btn btn-link pe-3 ps-0 ms-auto" href="javascript:;">{{ member?.careers_count || 0 }}</a>
                        </li>

                        <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
                            <img
                                class="me-3"
                                width="24"
                                height="24"
                                src="~@/assets/img/icons/gift-card.svg"
                                alt="Gift Cards"
                                border-radius="lg"
                            />
                            <div class="d-flex align-items-start flex-column justify-content-center">
                                <h6 class="mb-0 text-sm">Gift Cards</h6>
                                <!-- <p class="mb-0 text-xs">Have a great afternoon..</p> -->
                            </div>
                            <a class="mb-0 btn btn-link pe-3 ps-0 ms-auto" href="javascript:;">{{ member?.gift_cards_count || 0 }}</a>
                        </li>

                        <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
                            <img
                                class="me-3"
                                width="24"
                                height="24"
                                src="~@/assets/img/icons/review.svg"
                                alt="Reviews"
                                border-radius="lg"
                            />
                            <div class="d-flex align-items-start flex-column justify-content-center">
                                <h6 class="mb-0 text-sm">Reviews</h6>
                                <!-- <p class="mb-0 text-xs">Have a great afternoon..</p> -->
                            </div>
                            <a class="mb-0 btn btn-link pe-3 ps-0 ms-auto" href="javascript:;">{{ member?.reviews_count || 0 }}</a>
                        </li>

                    </ul>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import { mapState } from 'vuex'
import ProfileInfoCard from '@/components/venue/ProfileInfoCard'

// JAVASCRIPT
import setTooltip from '@/assets/js/tooltip.js'
import setNavPills from '@/assets/js/nav-pills.js'

// ICONS
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'

export default {
    props: ['id'],

    name: 'MemberInfo',

    components: {
        ProfileInfoCard,
    },

    data() {
        return {
            showMenu: false,
            faFacebook,
            faTwitter,
            faInstagram,
        }
    },

    computed: {
        ...mapState({
            member: state => state.member.member,
        }),
    },

    mounted() {
        this.$store.state.isAbsolute = true
        setNavPills()
        setTooltip(this.$store.state.bootstrap)
    },

    beforeUnmount() {
        this.$store.state.isAbsolute = false
    },

}
</script>
